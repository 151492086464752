<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="我的证书" left-arrow @click-left="$router.back()">
      </van-nav-bar>
    </van-sticky>

    <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">

      <div class="van-clearfix image-list">
        <div class="image-item" @click="toDetail(item.id.toString())" v-for="item in articles" :key="item.id.toString()">

          <div class="image-wrapper">
          <van-image lazy-load :src="item.imageUrl">
          </van-image>
          <div class="image-description">
            <h3 style="display: inline-block">{{ item.title }}</h3>
            <h4>{{ item.remark ? item.remark : item.creationTime }}</h4>
          </div>
        </div>
        </div>

      </div>

    </van-list>
  </div>
</template>
  
<script>
import { getMyCertificate } from "@/api/userCertificate";

//const key = "zn-history";
export default {
  name: "myCertificate",
  data() {
    return {
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 10,
        fromType: 1,
        filterData: {},
      },
    };
  },
  components: {},
  created() {
    this.onSearch();
  },
  updated() {
    // this.addpreview();
  },

  mounted() { },

  methods: {
    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 10;
      this.onLoad();
    },
    toDetail(id) {
      this.$router.push({
        path: "/user/mycertificatedetail",
        query: { para: id },
      });
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getMyCertificate({ ...this.pagePara });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.articles.id);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>
  
<style lang="less" scoped>


.image-list {
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  width: calc(33.33% - 10px);
  margin: 5px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-wrapper   /deep/ img {
  display: block;
  width: 100%;
  height: 15vh; /* 设置图片高度为视口高度的 20% */
  object-fit: cover;
}

.image-description {
  text-align: center;
}

.zntitle {
  height: 80px;
}

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 46px 10px 44px;

  .title {
    font-size: 18px;
    line-height: 2;
  }

  .zan {
    text-align: center;
    padding: 10px 0;

    .active {
      border-color: red;
      color: red;
    }
  }

  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;

    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;

      .name {
        font-size: 14px;
        margin: 0;
      }

      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }

  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;

    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }

    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
  